import React, { forwardRef } from 'react'
import emailjs from 'emailjs-com'
import Swal from 'sweetalert2'
import Socials from '../Socials/Socials';

const SERVICE_ID = "service_2e44nsf";
const TEMPLATE_ID = "template_hir3r5r";
const USER_ID = "6kXs6LJHQtcdtS4kd";

const Contact = forwardRef((props, ref) => {

  const handleOnSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Message bien reçu, nous allons revenir vers vous rapidement !'
        })
      }, (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Ooops, il y a eu une erreur',
          text: error.text,
        })
      })
    e.target.reset()
  }

  const renderForm = () => (
    <form onSubmit={handleOnSubmit}>
      <div className="Contact__form">
        <input
          className="Contact__input"
          required
          id="form-input-control-last-name"
          name='user_name'
          placeholder="Votre nom.."
        />
        <input
          className="Contact__input"
          required
          type="email"
          id="form-input-control-email"
          name='user_email'
          placeholder="Votre email"
        />
        <textarea
          className="Contact__input Contact__input--message"
          rows="4"
          required
          id="form-textarea-control-opinion"
          name='user_message'
          placeholder="Votre message.." />
        <button
          className="Contact__send"
          type='submit'>
          Nous écrire
        </button>
      </div>
    </form>
  )

  const renderSocials = () => (
    <div className="Contact__socials" >
      <Socials />
    </div>
  )

  return (
    <section ref={ref} className="Contact" id="contact">
      <div className="Contact__content">
        <div className="Contact__overlay" />
        <h1 className="Contact__title">
          Nous contacter
        </h1>
        <div className="Contact__flex">
          {renderForm()}
          {renderSocials()}
        </div>
      </div>

    </section>
  )
})

export default Contact
