import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import Logo from '../../assets/images/logo.jpeg'
import { getDisplayableInitials } from '../../utils/utils'
import { useSelector } from 'react-redux'

const Header = () => {
  const user = useSelector((state) => state.user.data)
  const [showMenu, setShowMenu] = useState(false)
  const closeMenu = () => setShowMenu(false)
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 })

  const linkClass = "Header__link"
  const activeLinkClass = "Header__link Header__link--active"


  return (
    <section className={`Header ${isTabletOrMobile ? 'Header--mobile' : ''}`}>
      <NavLink to="/">
        <img className="Header__logo" alt="Solidarité start-up" src={Logo} />
      </NavLink>

      <div onClick={() => setShowMenu(!showMenu)} className="Header__menu">
        <div className={`Header__menuIcon ${showMenu ? 'Header__menuIcon--active' : ''}`}>
          <div />
        </div>

      </div>
      <div className={`Header__links ${showMenu ? 'Header__links--active' : ''}`}>
        <NavLink onClick={closeMenu} className={({ isActive }) => isActive ? activeLinkClass : linkClass} to="/contact-us" data-content="Nous contacter">
          Nous contacter
        </NavLink>
        <NavLink onClick={closeMenu} className={({ isActive }) => isActive ? activeLinkClass : linkClass} to="/" data-content="Qui sommes nous ?">
          Qui sommes nous ?
        </NavLink>
        <NavLink onClick={closeMenu} className={({ isActive }) => isActive ? activeLinkClass : linkClass} to="/dashboard" data-content="Votre espace vidéos">
          Votre espace vidéos
        </NavLink>
        {user ?
          <NavLink onClick={closeMenu} className="Header__link Header__link--account" to="/profil" data-content={user?.displayName}>
            <span>{getDisplayableInitials(user?.displayName)}</span> Mon compte
          </NavLink>
          :
          <NavLink onClick={closeMenu} className="Header__link Header__link--login" to="/login" data-content="Se connecter">
            Se connecter
          </NavLink>
        }
      </div>
    </section>
  )
}

export default Header
