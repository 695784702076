import React from 'react';
import VideoCard from '../../../components/Cards/VideoCard';

const CoursesSectionList = (props) => {
  const { theme } = props
  const { videos } = theme || {}
  return (
    <section className="CoursesSectionList">
      <div className='CoursesSectionList__title'>
        <h1>
          {theme.label}
        </h1>
      </div>
      <div className='CoursesSectionList__content'>
        {videos.map((video, i) => (
          <VideoCard
            key={`video-${i}`}
            video={video}
            nextVideoId={videos[i + 1]?.id}
            {...props}
          />

        ))}
      </div>
    </section>
  )
}

export default CoursesSectionList
