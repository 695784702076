import { createSlice } from '@reduxjs/toolkit'

export const homeSlice = createSlice({
  name: 'home',
  initialState: {
    isLoading: true,
    data: null
  },
  reducers: {
    setHome: (state, action) => {
      state.data = action.payload
      state.isLoading = false
    }
  }
})

export const {
  setHome
} = homeSlice.actions


export default homeSlice.reducer
