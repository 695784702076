import { combineReducers } from 'redux'
import videos from './videos/reducer'
import user from './user/reducer'
import plans from './plans/reducer'
import home from './home/reducer'


export default combineReducers({
  videos,
  user,
  plans,
  home
})
