import React, { useState } from 'react'

import People1 from '../../../assets/images/fayza.jpeg'
import People2 from '../../../assets/images/marie.jpeg'

const Reviews = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const reviews = [
    {
      name: 'Fayza BASINI',
      job: 'Conseillère municipale de Neuilly-Sur-Seine',
      quotes: "J’ai soutenu Solidarité Start up et son fondateur Taha car il défend les causes de l’égalité des chances et de l’esprit d’entreprendre dans les quartiers difficiles des Hauts-de-Seine. Des causes qui me sont chères et qui animent mon propre engagement  via l’association INITIATIVES EN SEINES que j’ai fondée. J’ai noué avec SOLIDARITÉ START UP un partenariat pour mettre à leur disposition le vivier de mentors de mon association. Taha est déterminé, plein de ressources, avec un sens aigu du relationnel. Il a su s’entourer pour rendre rapidement l’association opérationnelle avec trois projets aboutis dès la première année d’existence. Bravo Solidarité start up pour ce début prometteur!",
      url: People1
    },
    {
      name: 'Marie Ange BADIN',
      job: 'Conseillère municipale de Clichy-La-Garenne',
      quotes: "Donner confiance pour permettre au talent de s’exprimer et se révéler pleinement, c’est toute la force de Solidarité Start-up ! L’entrepreneuriat est un défi qui peut paraître insurmontable lorsqu’on se sent seul et pourtant ce sont ni les idées ni l’envie qui manquent chez les jeunes de 18 à 25 ans ! En les accompagnant techniquement et juridiquement, en créant le réseau et l’entourage qui permettent de puiser la force de persévérer, Solidarité Start-up donne une chance aux jeunes d’y croire. Sous la présidence de Taha Sarhan, jeune président déterminé et ouvert au monde, Solidarité Start-up ce sont des jeunes qui parlent aux jeunes. Un moyen de renforcer le lien humain essentiel à notre société et de donner confiance à des jeunes qui peuvent l’avoir perdue au fil des années. Réaliser ses rêves, donner du sens à sa vie et s’accomplir dans son travail doivent être à la portée de tous, c’est toute l’ambition de Solidarité start-up. Bravo !",
      url: People2
    }
  ]

  const review = reviews[activeIndex]
  return (
    <section className="Discover__reviews sideContainer">
      <h1 className="Discover__title">Ce qu’il disent sur nous...</h1>
      <div className="Discover__reviews-cards">

        <span className="Discover__reviews-arrow Discover__reviews-arrow--left"  >
          <i onClick={() => setActiveIndex(activeIndex > 0 ? activeIndex - 1 : reviews.length - 1)} className="fa-solid fa-chevron-left"></i>
        </span>

        <div className="Discover__reviews-card">
          <div className="Discover__reviews-slider">
            <p className="Discover__reviews-quote">”{review.quotes}”</p>
          </div>
          <div className="Discover__reviews-author">
            <img alt="Solidarité start-up" src={review.url} />
            <p>
              {review.name} <br /> <span>{review.job}</span>
            </p>
          </div>
        </div>

        <span className="Discover__reviews-arrow" >
          <i onClick={() => setActiveIndex(activeIndex < reviews.length - 1 ? activeIndex + 1 : 0)} className="fa-solid fa-chevron-right"></i>
        </span>

      </div>
    </section>
  )
}

export default Reviews