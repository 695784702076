
import { setIsLoading, setVideosByThemes } from '../../reducers/videos/reducer';
import { API_URL } from '../../utils/constants';

const getThemes = async () => {
  return new Promise((resolve, reject) => {
    fetch(`${API_URL}/themes`)
      .then((themes) => themes.json())
      .then((resp) => resolve(resp))
      .catch((err) => reject(err));
  });
}

const getVideos = async () => {
  return new Promise((resolve, reject) => {
    fetch(`${API_URL}/videos`)
      .then((videos) => videos.json())
      .then((resp) => resolve(resp))
      .catch((err) => reject(err));
  });
}

export const getVideosByTheme = () => async (dispatch) => {
  dispatch(setIsLoading(true))

  getThemes().then((themes) => {
    console.log(themes)
    getVideos().then((videos) => {
      let videosFilteredByThemes = themes
      videosFilteredByThemes.forEach(theme => {
        const videosFiltered = videos.filter(video => video.theme === theme.slug)
        theme.videos = videosFiltered
      })
      dispatch(setVideosByThemes(videosFilteredByThemes))
      dispatch(setIsLoading(false))
    })
      .catch((err) => console.log(err))
  })

}