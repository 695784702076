import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../components/Loader/Loader'
import { getOffers } from '../../../reducers/plans/actions'
import { SUBSCRIPTION_INTERVALS, SUBSCRIPTION_INTERVALS_LABEL } from '../../../utils/constants'
import { createCheckoutSession } from '../../../utils/stripe/createCheckoutSession'

const Offers = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.data)
  const plans = useSelector((state) => state.plans.items)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!plans) {
      dispatch(getOffers())
    }
  }, [])

  const openSession = async (id) => {
    setIsLoading(true)
    return createCheckoutSession(user?.uid, id)
  }

  if (!plans || isLoading) {
    return <Loader />
  }

  const renderOfferCards = (plan, i) => {
    const {
      id,
      data: {
        description: title,
        interval,
        unit_amount,
        metadata
      }

    } = plan || {}

    return (
      <div key={i} className="Discover__offers-card">
        <h1 className="Discover__offers-title">{title}</h1>
        <h1 className="Discover__offers-price">{interval === SUBSCRIPTION_INTERVALS.YEAR && <span>143.88€</span>}{`${unit_amount / 100}€${SUBSCRIPTION_INTERVALS_LABEL[interval]}`}</h1>
        <p className='Discover__offers-label'>{metadata?.description}</p>
        <ul className="Discover__offers-list">
          <li>Toutes les videos de chaque domaine disponible !</li>
          <li>Des accompagnements personnalisées !</li>
          <li>Prise de rendez-vous en ligne et physique</li>
        </ul>
        <button onClick={() => openSession(id)} className="Discover__offers-subscribe">Adhérer à cette offre !</button>
      </div>
    )
  }


  return (
    <section className="Discover__offers sideContainer">
      <h1 className="Discover__title">Nos adhésions</h1>
      <div className="Discover__offers-cards">
        {plans?.map((plan, i) => renderOfferCards(plan, i))}
      </div>
    </section>
  )
}

export default Offers