import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, Navigate } from 'react-router-dom'
import firebase from "firebase/compat/app"
import "firebase/compat/firestore";

import Login from '../pages/Login/Login'
import InternalRoutes from './InternalRoutes'

import ScrollToTop from '../utils/ScrollToTop'
import { auth } from '../utils/firebase.config'
import { onAuthStateChanged } from 'firebase/auth'
import Loader from '../components/Loader/Loader'
import { setUser } from '../reducers/user/reducer'
import { setUserSubscription } from '../reducers/user/actions';

const RoutesComponent = () => {
  const user = useSelector((state) => state.user.data)
  const isLoading = useSelector((state) => state.user.isLoading)
  const dispatch = useDispatch()

  useEffect(() => {
    onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser?.emailVerified) {
        const { displayName, email, emailVerified, uid } = currentUser
        dispatch(setUser({ data: { displayName, email, emailVerified, uid } }))
      } else {
        dispatch(setUser({ data: null }))
      }
    })
  }, [])

  useEffect(() => {
    if (user?.uid && !user?.subscriptionInfos) {
      dispatch(setUserSubscription(user))
    }
  }, [user])



  const renderLogin = () => (isLoading ? <Loader /> : <Login />)

  return (
    <ScrollToTop>
      <Routes>
        <Route path="*" element={<InternalRoutes user={user} isLoading={isLoading} />} />
        <Route exact path="/login" element={
          user ? (
            <Navigate replace to="/dashboard" />
          ) : (
            renderLogin()
          )
        }
        />
      </Routes>
    </ScrollToTop>
  )
}


export default RoutesComponent
