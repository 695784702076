import React from 'react'
import PropTypes from 'prop-types'
import { Route, Routes, Navigate } from 'react-router-dom'

import Profil from '../pages/Profil/Profil'
import Layout from '../pages/Containers/Layout'
import Discover from '../pages/Discover/Discover'
import Contact from '../components/Contact/Contact'
import Dashboard from '../pages/Dashboard/Dashboard'
import SingleVideo from '../pages/SingleVideo/SingleVideo'
import Loader from '../components/Loader/Loader'
import Offers from '../pages/Discover/subComponents/Offers'

const LayoutRoutes = ({ user, isLoading }) => {

  const renderLogin = () => (isLoading ? <Loader /> : <Navigate replace to="/login" />)

  return (
    <Routes>
      <Route exact path="/" element={<Discover />} />
      <Route exact path="/contact-us" element={<Contact />} />
      <Route exact path="/offers" element={<Offers />} />
      <Route exact path="/dashboard" element={
        user ? (
          <Dashboard />
        ) : (
          renderLogin()
        )
      }
      />
      <Route exact path="/profil" element={
        user ? (
          <Profil user={user} />
        ) : (
          <Navigate replace to="/" />
        )
      }
      />
      <Route exact path="/course/:id" element={<SingleVideo />} />
    </Routes>
  )
}

LayoutRoutes.propTypes = {
  user: PropTypes.object
}

const InternalRoutes = (props) => (
  <Layout {...props}>
    <LayoutRoutes {...props} />
  </Layout>
)

export default InternalRoutes
