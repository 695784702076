import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    isLoading: true,
    data: null
  },
  reducers: {
    setUser: (state, action) => {
      state.data = action.payload.data
      state.isLoading = false
    }
  }
})

export const {
  setUser
} = userSlice.actions


export default userSlice.reducer
