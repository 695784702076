import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux'
import SideBar from './subComponents/SideBar';
import Offers from '../Discover/subComponents/Offers';
import Courses from './Courses';
import Events from './Events';
import { DASHBOARD_SECTIONS, DASHBOARD_SECTIONS_NAME } from '../../utils/constants';

const Dashboard = () => {
  const offersRef = useRef(null)
  const [activeTab, setActiveTab] = useState(DASHBOARD_SECTIONS[0])
  const user = useSelector((state) => state.user?.data)


  const dashboardComponents = {
    [DASHBOARD_SECTIONS_NAME.COURSES]: Courses,
    [DASHBOARD_SECTIONS_NAME.EVENTS]: Events
  }

  const DashboardComponent = dashboardComponents[activeTab.name]

  return (
    <section className="Dashboard">
      <DashboardComponent isSubscribe={user?.subscriptionInfos} />
    </section>
  )
}

export default Dashboard
