import React from 'react';
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  signInWithPopup
} from 'firebase/auth'

import { auth } from '../../utils/firebase.config';

const SocialLogin = () => {
  const SOCIALS = {
    GOOGLE: 'google',
    FACEBOOK: 'facebook',
    TWITTER: 'twitter'
  }

  const signIn = async (social) => {
    let provider
    if (social === SOCIALS.GOOGLE) {
      provider = new GoogleAuthProvider()
    } else if (social === SOCIALS.FACEBOOK) {
      provider = new FacebookAuthProvider()
    } else {
      provider = new TwitterAuthProvider()
    }

   await signInWithPopup(auth, provider)
      .then((result) => {
        console.log(result)
      })
      .catch((error) => {
        console.log(error)
      });
  }

  return (
    <div className="SocialLogin">
      <div className="SocialLogin__links">
        <i onClick={() => signIn(SOCIALS.GOOGLE)} className="fa fa-brands fa-google"></i>
      </div>
      <h4 className="SocialLogin__title">OU</h4>
    </div>
  )
}

export default SocialLogin
