import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import { getVideosByTheme } from '../../reducers/videos/actions';
import Loader from '../../components/Loader/Loader';
import CoursesSectionList from './subComponents/CoursesSectionList';

const Courses = (props) => {
  const dispatch = useDispatch()
  const videosByThemes = useSelector((state) => state.videos.items)
  const isLoading = useSelector((state) => state.videos.isLoading)

  useEffect(() => {
    if (!videosByThemes) {
      dispatch(getVideosByTheme())
    }
  }, [])

 
  if (!videosByThemes && isLoading) {
    return <Loader />
  }

  if (!videosByThemes) {
    return null
  }

  return (
    <section className="Courses sideContainer">
      <h1 className="Courses__title">Votre espace vidéos</h1>
      {videosByThemes?.map((theme, i) => <CoursesSectionList key={i} theme={theme} {...props} />)}
    </section>
  )
}

export default Courses
