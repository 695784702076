import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import logger from 'redux-logger'


import './assets/styles/sass/styles.scss'
import { configureStore } from '@reduxjs/toolkit'

import reducer from './reducers/reducers'
import RoutesComponent from './routes/routes'

const root = ReactDOM.createRoot(document.getElementById('root'))

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
})

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <RoutesComponent />
    </BrowserRouter>
  </Provider>
)