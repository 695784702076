import React, { useRef, useState } from 'react'
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  deleteUser
} from 'firebase/auth'

import firebase from "firebase/compat/app"
import "firebase/compat/firestore";

import { auth } from '../../utils/firebase.config'
import SocialLogin from '../SocialLogin/SocialLogin'

const SignIn = ({ setIsLogin }) => {

  const loginEmail = useRef(null)
  const loginPassword = useRef(null)

  const [errorMessage, setErrorMessage] = useState('')

  const handleSignIn = async (e) => {
    e.preventDefault()
    try {
      const userCreditentials = await signInWithEmailAndPassword(
        auth,
        loginEmail.current.value,
        loginPassword.current.value
      )

      firebase.firestore().collection("users").doc(String(userCreditentials.user.uid)).set({
        uid: userCreditentials.user.uid,
        email: userCreditentials.user.email,
        displayName: userCreditentials.user.displayName,
        provider: userCreditentials.user.providerData[0].providerId
      })
      setErrorMessage('')
    } catch (error) {
      console.log(error)
      setErrorMessage("L'email et/ou le mot de passe ne correspond pas")
    }
  }

  const sendPasswordEmail = () => {
    console.log(loginEmail.current.value)
    sendPasswordResetEmail(auth, loginEmail.current.value).then((result) => {
      console.log(result)
    }).catch((error) => {
      console.log(error)
      setErrorMessage("Aucun compte n'existe avec cet email")
    })
  }

  const renderForm = () => (
    <form onSubmit={e => handleSignIn(e)}>
      <input
        ref={loginEmail}
        className="SignIn__input"
        type="email"
        placeholder="Email"
        required
      />
      <input
        ref={loginPassword}
        className="SignIn__input"
        type="password"
        placeholder="Mot de passe"
        required
      />
      <span onClick={() => sendPasswordEmail()} className="SignIn__passwordForget">Mot de passe oublie ?</span>
      {errorMessage && <span className="Login__error">{errorMessage}</span>}
      <input
        className="SignIn__submit"
        type="submit"
        value="Se connecter"
        required
      />
      <p className="Login__linkTo">Vous n'avez pas encore de compte ? <span onClick={() => setIsLogin(false)}>Inscrivez-vous gratuitement</span></p>
    </form>
  )
  return (
    <section className="SignIn">
      <div className="SignIn__content">
        <h3>Connexion</h3>
        <SocialLogin />
        {renderForm()}
      </div>
    </section>
  )
}

export default SignIn
