import React from 'react'

const Socials = () => {
  return (
    <>
      <a href="https://twitter.com/solistartup" target='_blank' rel="noreferrer" aria-label='Twitter' >
        <i className="fa fa-brands fa-twitter"></i>
      </a>
      <a href="https://www.linkedin.com/in/solidarit%C3%A9-start-up-b962a1227/" target='_blank' rel="noreferrer" aria-label='Linkedin' >
        <i className="fa fa-brands fa-linkedin"></i>
      </a>
      <a href="https://www.instagram.com/solidaritestartup/" target='_blank' rel="noreferrer" aria-label='Instagram' >
        <i className="fa fa-brands fa-instagram"></i>
      </a>
      <a href="https://www.tiktok.com/@solidartitestartup?_t=8XdAeIYtxKP&_r=1" target='_blank' rel="noreferrer" aria-label='Tik tok' >
        <i className="fa fa-brands fa-tiktok"></i>
      </a>

    </>
  )
}

export default Socials
