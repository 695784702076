import { createSlice } from '@reduxjs/toolkit'

export const plansSlice = createSlice({
  name: 'plans',
  initialState: {
    isLoading: true,
    items: null
  },
  reducers: {
    setPlans: (state, action) => {
      state.items = action.payload
      state.isLoading = false
    }
  }
})

export const {
  setPlans
} = plansSlice.actions


export default plansSlice.reducer
