import firebase from "firebase/compat/app"
import "firebase/compat/firestore";
import { setPlans } from "../plans/reducer";

export const getOffers = () => async (dispatch) => {
  try {
    const offersInfos = []
    firebase.firestore()
      .collection('products')
      .doc('prod_MqMEJKgECmoXMV')
      .collection("prices")
      .where("active", '==', true)
      .onSnapshot(async (snapshot) => {
        snapshot.docs.forEach(async function (doc) {
          offersInfos.push({ id: doc.id, data: doc.data() })
        });
        offersInfos.sort(function (a, b) {  return a.data.unit_amount - b.data.unit_amount;  })
        dispatch(setPlans(offersInfos))
        return offersInfos
      });

  } catch (error) {
    console.log(error)
  }
}