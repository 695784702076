import React from 'react';
import Logo from '../../assets/images/logo.jpeg'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const VideoCard = ({ video, nextVideoId }) => {
  const user = useSelector((state) => state.user.data)

  return (
    <div className={`VideoCard`}>
      <Link to={`/course/${video.id}`} state={{ nextVideoId }}>
        <i className='VideoCard__play fa-solid fa-play'></i>
        <div className='VideoCard__background' style={{ backgroundImage: `url(${Logo})` }} />
        <div className='VideoCard__meta'>
          <span className='VideoCard__playBtn'></span>
          <h1 className='VideoCard__title'>{video.name}</h1>
        </div>
      </Link>
      {!user && !video.free && (
        <Link to="/offers">
          <div className='VideoCard__privateOverlay'>
            <i className='fa-solid fa-lock'></i>
          </div>
        </Link>
      )}
    </div>
  )
}

export default VideoCard
