import React, { useEffect, useRef } from 'react'

import Intro from './subComponents/Intro'
import Offers from './subComponents/Offers'
import Services from './subComponents/Services'
import Reviews from './subComponents/Reviews'
import About from '../About/About'

const Discover = () => {
  return (
    <section className="Discover">
      <About />
      <Services />
      <Reviews />
      <Intro  />
    </section>
  )
}

export default Discover
