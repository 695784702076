import CoursesImg from '../assets/images/courses.png'
import EventsImg from '../assets/images/events.png'

export const ERROR_CODE = {
  INVALID_EMAIL: 'auth/invalid-email'
}

export const API_URL = "https://us-central1-solidarite-start-up.cloudfunctions.net/app"
export const LOCAL_API_URL = "http://127.0.0.1:5001/solidarite-start-up/us-central1/app"


export const DASHBOARD_SECTIONS_NAME = {
  COURSES: 'Courses',
  EVENTS: 'Events'
}

export const DASHBOARD_SECTIONS = [
  {
    name: [DASHBOARD_SECTIONS_NAME.COURSES],
    description: "Espace formation",
    img: CoursesImg,
    iconClass: "fa fa-solid fa-graduation-cap"
  },
  {
    name: [DASHBOARD_SECTIONS_NAME.EVENTS],
    description: "Nos evenements",
    img: EventsImg,
    iconClass: "fa fa-solid fa-calendar-days"
  },
]

export const SUBSCRIPTION_INTERVALS = {
  YEAR: 'year',
  MONTH: 'month'
}

export const SUBSCRIPTION_INTERVALS_LABEL = {
  [SUBSCRIPTION_INTERVALS.YEAR]: '/an',
  [SUBSCRIPTION_INTERVALS.MONTH]: '/mois'
}