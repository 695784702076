import firebase from "firebase/compat/app"
import "firebase/compat/firestore";
import { SUBSCRIPTION_INTERVALS_LABEL } from "../../utils/constants";
import { setPlans } from "../plans/reducer";
import { setUser } from "./reducer";

export const setUserSubscription = (user) => async (dispatch) => {
  try {
    firebase.firestore()
      .collection("users")
      .doc(user.uid)
      .collection("subscriptions")
      .where("status", '==', "active")
      .onSnapshot(async (snapshot) => {
        if (snapshot.empty) {
          return false
        }


        // In this implementation we only expect one Subscription to exist
        const subscription = snapshot.docs[0].data();
        const priceData = (await subscription.price.get()).data();
        const { description, unit_amount, interval } = priceData
        const subscriptionInfos = {
          description: description,
          currency: `${unit_amount / 100}${SUBSCRIPTION_INTERVALS_LABEL[interval]}`
        }

        dispatch(setUser({ data: { ...user, subscriptionInfos } }))
        return subscriptionInfos
      });
  } catch (error) {
    console.log(error)
  }
}