import firebase from "firebase/compat/app"
import "firebase/compat/firestore";
import Loader from "../../components/Loader/Loader";

import getStripe from './initializeStripe'

export async function createCheckoutSession(uid, priceId) {
  const firestore = firebase.firestore()
  console.log(priceId)
  const checkoutSessionRef = await firestore
    .collection("users")
    .doc(uid)
    .collection("checkout_sessions")
    .add({
      price: priceId,
      success_url: `${window.location.origin}`,
      cancel_url: `${window.location.origin}`,
    })

  checkoutSessionRef.onSnapshot(async (snap) => {
    console.log(snap.data())
    const { sessionId } = snap.data();
    console.log(sessionId)
    if (sessionId) {
      const stripe = await getStripe()
      stripe.redirectToCheckout({ sessionId }).then()
    }
    return <Loader />
  })
} 