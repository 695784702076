import React, { useEffect, useState, useRef } from 'react';
import {
  Player,
  ControlBar,
  ForwardControl,
  CurrentTimeDisplay,
  TimeDivider
} from 'video-react'
import { useSelector, useDispatch } from 'react-redux'
import { getVideosByTheme } from '../../reducers/videos/actions'


import { API_URL, LOCAL_API_URL } from '../../utils/constants';
import { useParams, useLocation, Link } from 'react-router-dom'
import Loader from '../../components/Loader/Loader';
import userEvent from '@testing-library/user-event';
import PrivateVideo from './subComponents/PrivateVideo';


const SingleVideo = () => {
  const { id } = useParams() || {}
  const location = useLocation()
  const videoRef = useRef()
  const dispatch = useDispatch()


  const videosByThemes = useSelector((state) => state.videos.items)
  const user = useSelector((state) => state.user.data)

  const [video, setVideo] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const isVideoPublic = user || video?.free

  const getSingleVideo = () => {
    fetch(`${API_URL}/api/read/${id}`)
      .then(response => response.json())
      .then(response => {
        setVideo(response)
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (id !== video?.id) {
      getSingleVideo()
    }
    if (!videosByThemes) {
      dispatch(getVideosByTheme())
    }
  }, [id, video?.id, videosByThemes])

  useEffect(() => {
    videoRef.current?.load();
  }, [video?.url])


  if (!video && isLoading) {
    return (
      <Loader />
    )
  }

  if (!isVideoPublic && !isLoading) {
    return <PrivateVideo />
  }

  const getNextVideoId = () => {
    const currentTheme = videosByThemes?.find(theme => theme.slug === video.theme)
    const nextVideo = currentTheme?.videos?.find(currentVideo => currentVideo.order === video.order + 1)
    if (nextVideo) {
      return { id: nextVideo.id, name: nextVideo.name }
    }
    return null
  }


  return (
    <section className="SingleVideo sideContainer">
      <div className="SingleVideo__header">
        <h1 className="SingleVideo__title">{video.name}</h1>
        <div className="SingleVideo__links">
          <Link to="/dashboard">
            Revenir aux videos
          </Link>
          {user && getNextVideoId() && (
            <Link to={`/course/${getNextVideoId().id}`}>
              Prochaine video: {getNextVideoId().name} {'>'}
            </Link>
          )}
        </div>
      </div>

      <div className="SingleVideo__video">
        <Player ref={videoRef}>
          <source src={video.url} />
          <ControlBar>
            <ForwardControl seconds={10} order={1.2} />
            <CurrentTimeDisplay order={4.1} />
            <TimeDivider order={4.2} />
          </ControlBar>
        </Player>
      </div>
    </section>
  )
}

export default SingleVideo
