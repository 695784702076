import React from 'react';
import { Link } from 'react-router-dom';

const PrivateVideo = () => {
  return (
    <div className='PrivateVideo sideContainer'>
      <h1 className='PrivateVideo__title'>Oops, vous n'avez pas accès à cette video.</h1>
      <i className='PrivateVideo__lock fa-solid fa-lock'></i>

      <p className='PrivateVideo__description'>Cette video est réserver exclusivement au adhérents de solidarité start-up.</p>
      <Link className='PrivateVideo__button' to="/offers">
        Je choisis une offre
      </Link>
    </div>
  );
}

export default PrivateVideo;