import React from 'react';

const Events = () => {
  return (
    <section className="Events">
    </section>
  )
}

export default Events
