import { createSlice } from '@reduxjs/toolkit'

export const videosSlice = createSlice({
  name: 'videos',
  initialState: {
    items: null,
    isLoading: false
  },
  reducers: {
    setVideosByThemes: (state, action) => {
      state.items = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    }
  }
})

export const {
  setVideosByThemes, setIsLoading
} = videosSlice.actions
export default videosSlice.reducer
