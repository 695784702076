import React, { useState } from 'react';

import LearningImg from '../../assets/images/learning.jpg'
import EducationImg from '../../assets/images/education.jpg'
import ThinkingImg from '../../assets/images/thinking.jpg'
import Loader from '../../components/Loader/Loader';

const About = () => {

  const [loaded, setLoaded] = useState(false)

  const renderIntro = () => (
    <section className="About__intro sideContainer">
      <h1 className="About__intro-title">
        L’association qui vous accompagne dans l’entreprenariat
      </h1>
      <p className="About__intro-description">
      Entreprendre dès un jeune âge peut-être un défi mais on considère souvent qu’un mentorat de qualité et une solide initiation sont les piliers d’une entreprise robuste
      </p>
    </section>
  )

  const renderImgs = () => (
    <div className="About__historyImgs sideContainer">
      <div>
        {!loaded && <Loader />}
        <img
          className="About__historyImg"
          alt="Solidarité start-up"
          src={LearningImg}
        />
      </div>
      <img className="About__historyImg About__historyImg--middle" alt="Solidarité start-up" src={EducationImg} />
      <div>
        <img
          className="About__historyImg"
          alt="Solidarité start-up"
          src={ThinkingImg}
          onLoad={() => setLoaded(true)}
        />
      </div>
    </div>
  )

  const renderStory = () => (
    <div className="About__story">
      <span className="About__story-label">Notre Histoire</span>
      <h1 className="About__story-title">Un groupe d’étudiant qui a acquis énormément de savoir</h1>
      <p className="About__story-description">
      En tant qu&#39;initiaux membres d&#39;un groupe de jeunes étudiants issus de divers domaines, nous avons
rapidement constaté qu&#39;au fil des années, les connaissances acquises au cours d&#39;une année
étaient souvent oubliées avec l&#39;arrivée de la nouvelle année. C&#39;est pourquoi nous avons
entrepris de trouver une solution pour préserver nos compétences tout en venant en aide à
un plus grand nombre de personnes. Aujourd&#39;hui, notre initiative rassemble non seulement
des enseignants-chercheurs, des entrepreneurs, des organisations, mais aussi des élus locaux,
tous partageant notre vision.
      </p>
    </div>
  )

  const renderHistory = () => (
    <section className="About__history">
      {renderImgs()}
      {renderStory()}
    </section>
  )

  const renderRessources = () => (
    <section className="About__ressources sideContainer">
      <h1 className="About__ressources-title">Nos ressources</h1>
      <div className="About__ressources-items">
        <div className="About__ressources-item">
          <h1 className="About__ressources-number">100+</h1>
          <span className="About__ressources-label">Videos</span>
        </div>
        <div className="About__ressources-item">
          <h1 className="About__ressources-number">10.000</h1>
          <span className="About__ressources-label">Heures</span>
        </div>
        <div className="About__ressources-item">
          <h1 className="About__ressources-number">240</h1>
          <span className="About__ressources-label">Autres</span>
        </div>
      </div>

    </section>
  )


  return (
    <section className="About">
      {renderIntro()}
      {renderHistory()}
    </section>
  )
}

export default About
