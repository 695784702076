import React from 'react';

import Logo from '../../assets/images/logo.jpeg'
import Socials from '../Socials/Socials';

import AsnieresLogo from '../../assets/images/partners/asnieres.png'
import NeuillyLogo from '../../assets/images/partners/neuilly.png'
import LevalloisLogo from '../../assets/images/partners/levallois.png'
import GennevilliersLogo from '../../assets/images/partners/gennevilliers.png'
import VilleneuveLogo from '../../assets/images/partners/villeneuve.png'
import ISCLogo from '../../assets/images/partners/ISC.png'

const Footer = () => {
  return (
    <section className="Footer">
      <img alt="Logo" className="Footer__logo" src={Logo} />
      <div className="Footer__socials">
        <h1>Nous suivre sur les réseaux !</h1>
        <div className="Footer__socials-content">
          <Socials />
        </div>
      </div>

      <div className="Footer__partners">
        <h1>Ils nous font confiance !</h1>
        <div>
          <img alt="Solidarité start-up" src={AsnieresLogo} />
          <img alt="Solidarité start-up" src={NeuillyLogo} />
          <img alt="Solidarité start-up" src={LevalloisLogo} />
          <img alt="Solidarité start-up" src={GennevilliersLogo} />
          <img alt="Solidarité start-up" src={VilleneuveLogo} />
          <img alt="Solidarité start-up" src={ISCLogo} />
        </div>

      </div>
    </section>
  )
}

export default Footer
