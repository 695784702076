import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {  onIdTokenChanged } from 'firebase/auth'

import { auth } from '../../utils/firebase.config'

import SignIn from '../../components/SignIn/SignIn'
import Register from '../../components/Register/Register'
import Logo from '../../assets/images/logo.jpeg'

const Login = () => {
  const [isLogin, setIsLogin] = useState(true)
  const currentUser = auth.currentUser;

  useEffect(() => {
    onIdTokenChanged(auth, async (currentUser) => {
      console.log(currentUser)
    })
      if (currentUser) {
        currentUser.reload();
      }
      console.log(currentUser)
  }, [])

  return (
    <section className="Login">
      <div className="Login__header">
        <Link to='/'>
          <img alt="logo" src={Logo} />
        </Link>
      </div>
      <div className="Login__content">

        {isLogin ?
          <SignIn setIsLogin={setIsLogin} /> :
          <Register setIsLogin={setIsLogin} />
        }
      </div>
    </section>
  )
}

export default Login
