import React from 'react'
import { Link } from 'react-router-dom'

import Logo from '../../../assets/images/logo.jpeg'

const Intro = () => {
  return (
    <section className="Discover__intro sideContainer">
      <div className="Discover__intro-presentation">
        <div className="Discover__intro-overlay" />
        <div className="Discover__intro-text">
          <h1 className="Discover__title">
           <span>Notre objectif :</span> 
            <br />
            Aider les jeunes à développer leur projets entrepreneurials
          </h1>
          <Link className="Discover__intro-button" to="/login">
            Nous rejoindre !
          </Link>
        </div>
        <img className="Discover__intro-image" alt="Solidarité start-up" src={Logo} />
      </div>
    </section>
  )
}

export default Intro