import React, { useRef, useState } from 'react';
import SocialLogin from '../SocialLogin/SocialLogin';
// import { isValidPassword } from '../../utils/utils';
import { auth } from '../../utils/firebase.config';
import { ERROR_CODE } from '../../utils/constants';
import Swal from 'sweetalert2';
import firebase from "firebase/compat/app"
import "firebase/compat/firestore";


const Register = ({ setIsLogin }) => {

  const registerUserName = useRef(null)
  const registerEmail = useRef(null)
  const registerPassword = useRef(null)

  const [errorMessage, setErrorMessage] = useState('')

  const handleRegister = (e) => {
    e.preventDefault()
    auth.createUserWithEmailAndPassword(
      registerEmail.current.value,
      registerPassword.current.value
    ).then(async (userAuth) => {
      await userAuth.user.updateProfile({
        displayName: registerUserName.current.value
      })
      Swal.fire({
        icon: 'success',
        title: 'Vous êtes bien inscrit',
        text: 'Veuillez cliquer sur le lien envoyé sur votre email pour valider l\'inscription, il peut apparaitre dans vos spams !'
      })
      var actionCodeSettings = {
        url: "https://www.solidarite-start-up.fr/dashboard"
      };
      
      userAuth.user.sendEmailVerification(actionCodeSettings)
      firebase.firestore().collection("users").doc(String(userAuth.user.uid)).set({
        uid: userAuth.user.uid,
        email: userAuth.user.email,
        displayName: userAuth.user.displayName,
        provider: userAuth.user.providerData[0].providerId
      })
    }).catch((error) => {
      if (error.code === ERROR_CODE.INVALID_EMAIL) {
        setErrorMessage("L'email n'est pas dans un bon format")
      }
    })
  }

  const renderForm = () => (
    <form onSubmit={e => handleRegister(e)}>
      <input
        ref={registerUserName}
        className="Register__input"
        type="text"
        placeholder="Pseudo"
        required
      />
      <input
        ref={registerEmail}
        className="Register__input"
        type="email"
        placeholder="Email"
        required
      />
      <input
        ref={registerPassword}
        className="Register__input"
        type="password"
        placeholder="Mot de passe"
        required
      />
      {errorMessage && <p className="Login__error">{errorMessage}</p>}
      <input
        className="Register__submit"
        type="submit"
        value="S'inscrire"
        required
      />
      <p className="Login__linkTo">Vous avez déjà un compte ? <span onClick={() => setIsLogin(true)}>Connectez-vous</span></p>
    </form>
  )

  return (
    <section className="Register">
      <div className="Register__content">
        <h3>Inscription</h3>
        <SocialLogin />
        {renderForm()}
      </div>
    </section>
  )
}

export default Register
