import firebase from "firebase/compat/app"
import { getAnalytics } from "firebase/analytics"
import "firebase/compat/auth"


const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "solidarite-start-up.firebaseapp.com",
  projectId: "solidarite-start-up",
  storageBucket: "solidarite-start-up.appspot.com",
  messagingSenderId: process.env.REACT_APP_SENDER_ID,
  appId: "1:708762701389:web:5257ddd5547fdf40769604",
  measurementId: "G-2L463Q5QDE"
})


export const analytics = getAnalytics(app)

export const auth = app.auth()
export default app