import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { signOut } from 'firebase/auth'
import { auth } from '../../utils/firebase.config'
import { getDisplayableInitials } from '../../utils/utils'
import { redirectToCustomerPortal } from '../../utils/stripe/redirectToCustomerPortal'
import { Link } from 'react-router-dom'
import Loader from '../../components/Loader/Loader'

const Profil = () => {
  const user = useSelector((state) => state.user.data)
  const [isLoading, setIsLoading] = useState(false)
  const handleLogOut = async () => {
    await signOut(auth)
  }

  const openPortal = async () => {
    setIsLoading(true)
    return redirectToCustomerPortal()
  }

  if (isLoading) {
    return <Loader />
  }

  // const renderMySubscription = () => (
  //   <div className="Profil__subscription">
  //     <h1 className="Profil__title">Mon abonnement</h1>

  //     {user?.subscriptionInfos ? (
  //       <>
  //         {/* <p>{user?.subscriptionInfos.description}</p>
  //         <p>{user?.subscriptionInfos.currency}</p> */}
  //         <p>Abonnement premium</p>
  //         <button className='Profil__button' onClick={() => openPortal()}>Gérer mon abonnement</button>
  //       </>
  //     ) : (
  //       <div>
  //         <p>Aucun abonnement actif</p>
  //         <Link to="/login" className='Profil__button'>
  //           Je choisis un abonnement !
  //         </Link>
  //       </div>
  //     )}
  //   </div>
  // )

  const renderMyInformations = () => (
    <div className="Profil__informations">
      <h1 className="Profil__title">Mes informations</h1>
      <p>{user.displayName}</p>
      <p>{user.email}</p>
      <button className="Profil__logout" onClick={() => handleLogOut()}>Deconnexion</button>
    </div>
  )
  const renderLogo = () => (
    <div className="Profil__logo">
      {getDisplayableInitials(user.displayName)}
    </div>
  )


  return (
    <section className="Profil sideContainer">
      {renderLogo()}
      <div className="Profil__content">
        {/* {renderMySubscription()} */}
        {renderMyInformations()}
      </div>

    </section>
  )
}

export default Profil
