import React from 'react'

import FormationLogo from '../../../assets/images/Formation.png'
import MentoratLogo from '../../../assets/images/Mentorat.png'

const Services = () => {
  return (
    <section className="Discover__services sideContainer">
      <h1 className="Discover__services-label">Nos services</h1>
      <div className="Discover__services-cards">
        <div className="Discover__services-card">
          <img className="Discover__services-image" alt="Solidarité start-up" src={FormationLogo} />
          <p className='Discover__services-text'>Initiations & Educations sur différents domaines</p>
        </div>
        <div className="Discover__services-card">
          <img className="Discover__services-image" alt="Solidarité start-up" src={MentoratLogo} />
          <p className='Discover__services-text'>Mentorat et accompagnement personnalisé</p>
        </div>
      </div>
    </section>
  )
}

export default Services